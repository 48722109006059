import { useEffect } from "react";
import { useExpand } from "@vkruglikov/react-telegram-web-app";
import LoadingIndicator from "../../component/loadingIndicator";
import { UserContext } from "./user-context";

export function GameConsumer({ children }: any) {
  const [isExpanded, expand] = useExpand();

  useEffect(() => {
    !isExpanded && expand();
  }, [expand, isExpanded]);

  return (
    <UserContext.Consumer>
      {(user: any) =>
        user.userState.loading ? <LoadingIndicator /> : children
      }
    </UserContext.Consumer>
  );
}
