import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import axios from "axios";
import { useUserContext } from "../../../hooks/use-user-context";
import OneStepForm from "./one";
import TwoStepForm from "./two";
import ThreeStepForm from "./three";
import ConfirmModal from "./modal/confirm";

type PageProps = {
  handlePage: (arg0: number) => void;
  setIsStartOver: (arg0: boolean) => void;
  isStartOver: boolean;
};

const schema = yup.object().shape({
  company: yup
    .string()
    .required("Company selection is required")
    .oneOf(["pontem", "lumio"], "Invalid company selection"),
  transaction: yup
    .string()
    .required("Transaction Types selection is required")
    .oneOf(["payment", "refund", "external"], "Invalid transaction selection"),
  name: yup
    .string()
    .required("Full Name is required")
    .min(2, "Full Name must be at least 2 characters")
    .max(50, "Full Name must be at most 50 characters"),
  description: yup
    .string()
    .max(250, "Description must be at most 250 characters"),
  department: yup
    .string()
    .when("transaction", {
      is: (val: string) => val === "payment",
      then: (schema) =>
        schema.required("Department selection is required for payment"),
    })
    .oneOf(
      ["development", "marketing", "management", "counterparty"],
      "Invalid department selection",
    ),
  currency: yup
    .string()
    .required("Currency is required")
    .matches(/^[^А-Яа-яЁё]+$/, "Cyrillic not allowed")
    .max(100, "Currency must be at most 100 characters"),
  network: yup
    .string()
    .required("Network is required")
    .matches(/^[^А-Яа-яЁё]+$/, "Cyrillic not allowed")
    .max(100, "Currency must be at most 100 characters"),
  wallet: yup
    .string()
    .required("Wallet Address is required")
    .matches(/^\S*$/, "Wallet Address must not contain spaces")
    .matches(/^[^А-Яа-яЁё]+$/, "Cyrillic not allowed")
    .max(100, "Currency must be at most 100 characters"),
  amount: yup
    .string()
    .required("Amount is required")
    .max(10, "Amount must be at most 10 characters"),
  file: yup
    .mixed()
    .test("fileSize", "File size is too large (max 15MB)", (value: any) => {
      if (!value || value.length === 0) return true;

      const maxFileSize = 15 * 1024 * 1024;
      for (let i = 0; i < value.length; i++) {
        if (value[i].size > maxFileSize) {
          return false;
        }
      }

      return true;
    })
    .test(
      "fileType",
      "The file must be a file of type: pdf, xls, xlsx, doc, docx, csv",
      (value: any) => {
        if (!value || value.length === 0) return true;

        const allowedExtensions = ["pdf", "xls", "xlsx", "doc", "docx", "csv"];

        for (let i = 0; i < value.length; i++) {
          const fileExtension = value[i].name.split(".").pop()?.toLowerCase();
          if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
            return false;
          }
        }

        return true;
      },
    ),
  server: yup.string(),
});

function TwoPage({ handlePage, isStartOver, setIsStartOver }: PageProps) {
  const [, initData] = useInitData();
  const { userState } = useUserContext();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    message: {
      currency: "",
      company: "",
      network: "",
      transaction: "",
      department: "",
      walletAmount: "",
      paymentDescription: "",
      currentDate: "",
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    clearErrors,
    trigger,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: userState.name,
      wallet: "",
      currency: "USDC",
      network: "ERC20",
      description: "",
    },
  });

  const fetch = useCallback(
    async (data: any) => {
      setLoading(true);

      try {
        const formDataToSend = new FormData();

        const fields = {
          userId: userState.id,
          company: data.company,
          network: data.network,
          name: data.name,
          currency: data.currency,
          transaction: data.transaction,
          amount: data.amount,
          wallet: data.wallet,
          department: data.transaction === "payment" ? data.department : "",
          description: data.description,
        };

        Object.entries(fields).forEach(([key, value]) => {
          if (value) {
            formDataToSend.append(key, value);
          }
        });

        if (data.file && data.file.length > 0) {
          formDataToSend.append("file", data.file[0]);
        }

        await axios.post(
          `${process.env.REACT_APP_BACK_API}/user-payments`,
          formDataToSend,
          {
            headers: {
              Authorization: initData,
              "Content-Type": "multipart/form-data",
            },
          },
        );
        handlePage(3);
      } catch (error: any) {
        console.log(error, "error");
        setError("server", {
          type: "server",
          message: error?.response?.data?.message || "Something went wrong",
        });
      } finally {
        setLoading(false);
      }
    },
    [userState.id, initData, handlePage, setError],
  );

  const onSubmit = useCallback(async () => {
    setModalData({
      title: "Please check information",
      message: {
        currency: watch("currency"),
        company: watch("company"),
        transaction: watch("transaction"),
        department:
          watch("transaction") === "payment" ? watch("department") || "" : "",
        network: watch("network"),
        walletAmount: `${watch("wallet")} ${watch("amount")}`,
        paymentDescription: watch("description") || "-",
        currentDate: new Date().toLocaleDateString(),
      },
    });

    setIsModalOpen(true);
  }, [watch]);

  const handleConfirm = handleSubmit(async (data: any) => {
    setIsModalOpen(false);
    await fetch(data);
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderPage = () => {
    switch (step) {
      case 1:
        return (
          <OneStepForm
            control={control}
            setStep={setStep}
            setValue={setValue}
            errors={errors}
            trigger={trigger}
            watch={watch}
          />
        );
      case 2:
        return (
          <TwoStepForm
            setStep={setStep}
            watch={watch}
            clearErrors={clearErrors}
            control={control}
            errors={errors}
            trigger={trigger}
          />
        );
      case 3:
        return (
          <ThreeStepForm
            setStep={setStep}
            watch={watch}
            trigger={trigger}
            errors={errors}
            setValue={setValue}
            control={control}
            loading={loading}
            setError={setError}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isStartOver) {
      setStep(1);
      reset();
      setIsStartOver(false);
    }
  }, [isStartOver, reset, setIsStartOver]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-2.5 w-full max-w-lg"
      >
        {renderPage()}
      </form>
      <ConfirmModal
        isOpen={isModalOpen}
        title={modalData.title}
        message={modalData.message}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </>
  );
}

export default TwoPage;
