import React from "react";

function ErrorComponent({ error }: { error: any }) {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-xl font-bold mb-4">Open on your mobile phone</h1>
      <img
        width={220}
        height={220}
        src="/images/QR-code_telegram.png"
        alt="QR-code"
        className="rounded-lg"
      />
      <a href="https://t.me/pon_lum_bot" className="mt-4 text-2xl">
        @pon_lum_bot
      </a>
    </div>
  );
}

export default ErrorComponent;
