import React from "react";

function LoadingIndicator() {
  return (
    <div className="container mx-auto p-3 min-h-screen flex items-center justify-center">
      <div className="spinner-circle" />
    </div>
  );
}

export default LoadingIndicator;
