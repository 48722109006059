import React, { useCallback, useState } from "react";
import { Controller } from "react-hook-form";
import { ArrowLongLeftIcon } from "@heroicons/react/16/solid";
import axios from "axios";
import debounce from "lodash.debounce";

interface ThreeStepFormProps {
  control: any;
  errors: any;
  watch: any;
  trigger: any;
  loading: boolean;
  setValue: (field: any, value: any) => void;
  setStep: (step: number) => void;
  setError: (name: any, error: any) => void;
}

function ThreeStepForm({
  control,
  errors,
  setValue,
  loading,
  setStep,
  setError,
  watch,
}: ThreeStepFormProps) {
  const [loadingWallet, setLoadingWallet] = useState(false);
  const [warning, setWarning] = useState({
    isWarning: false,
    message: "",
  });

  const validateWallet = useCallback(
    debounce(async (value: string) => {
      if (!value) {
        return;
      }
      const baseUrl = "https://api.etherscan.io/api";
      const txlistUrl = `${baseUrl}?module=account&action=txlist&address=${value}&tag=latest&offset=1&page=1&apikey=${process.env.REACT_APP_API_KEY}`;

      setLoadingWallet(true);

      try {
        const txlistResponse = await axios.get(txlistUrl);
        if (txlistResponse.data.status === "0") {
          if (
            !txlistResponse.data?.result ||
            txlistResponse.data?.result?.length === 0
          ) {
            setWarning({
              isWarning: true,
              message:
                txlistResponse.data.message ||
                "An unexpected issue occurred. Please try again later.",
            });

            setError("wallet", {
              type: "server",
              message: null,
            });
          } else {
            setWarning({
              isWarning: false,
              message: "",
            });

            setError("wallet", {
              type: "server",
              message: txlistResponse.data.result,
            });
          }
        }
        if (txlistResponse.data.status === "1") {
          setWarning({
            isWarning: false,
            message: "",
          });

          setError("wallet", {
            type: "server",
            message: null,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingWallet(false);
      }
    }, 500),
    [],
  );

  return (
    <>
      <div>
        <p className="mb-1">Please add currency:</p>
        <Controller
          name="currency"
          control={control}
          render={({ field }) => (
            <input
              className="input input-block input-primary"
              placeholder="Amount USDC, USDT, etc"
              {...field}
            />
          )}
        />
      </div>

      {errors.currency && (
        <p className="text-red-700 text-xs">
          {errors.currency.message as string}
        </p>
      )}

      <div>
        <p className="mb-1">Please add network:</p>
        <Controller
          name="network"
          control={control}
          render={({ field }) => (
            <input
              className="input input-block input-primary"
              placeholder="Network ERC20 or other network"
              {...field}
            />
          )}
        />
      </div>

      {errors.network && (
        <p className="text-red-700 text-xs">
          {errors.network.message as string}
        </p>
      )}

      <div>
        <p className="mb-1">Please add your wallet address:</p>
        <Controller
          name="wallet"
          control={control}
          render={({ field }) => (
            <div className="relative">
              <input
                className={`input input-block input-primary ${loadingWallet ? "pr-10" : "pr-3"}`}
                placeholder="Wallet address (e.g. ERC20 USDC)"
                {...field}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(e);
                  if (watch("transaction") === "payment") {
                    validateWallet(value);
                  }
                }}
              />

              {loadingWallet && (
                <div className="spinner-circle spinner-sm absolute inset-0 mr-1.5 m-auto w-6 h-6" />
              )}
            </div>
          )}
        />
      </div>

      {(errors.wallet || warning.isWarning) && (
        <>
          {warning.isWarning ? (
            <p className="text-orange-400 text-xs">
              Warning: {warning.message}
            </p>
          ) : (
            <p className="text-red-700 text-xs">
              {errors.wallet.message as string}
            </p>
          )}
        </>
      )}

      <div>
        <p className="mb-1">Please add amount:</p>
        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <input
              type="number"
              className="input input-block input-primary"
              placeholder="Amount USDC"
              min={0}
              {...field}
            />
          )}
        />
      </div>

      {errors.amount && (
        <p className="text-red-700 text-xs">
          {errors.amount.message as string}
        </p>
      )}

      <div>
        <p className="mb-1">Please add your invoice:</p>
        <Controller
          name="file"
          control={control}
          render={({ field }) => (
            <input
              type="file"
              accept=".pdf,.xls,.xlsx,.doc,.docx,.csv"
              className="input-file input-file-primary w-full max-w-full"
              onChange={(e) => field.onChange(e.target.files)}
            />
          )}
        />
      </div>

      {errors.file && (
        <p className="text-red-700 text-xs">{errors.file.message as string}</p>
      )}

      {errors.server && (
        <p className="text-red-700 text-xs">
          {errors.server.message as string}
        </p>
      )}

      <button
        className="w-full btn btn-primary"
        type="submit"
        disabled={loading}
      >
        {loading ? (
          <svg
            className="spinner-ring spinner-success spinner-sm"
            viewBox="25 25 50 50"
            strokeWidth="5"
          >
            <circle cx="50" cy="50" r="20" />
          </svg>
        ) : (
          "Confirm"
        )}
      </button>

      <div className="flex gap-4">
        <button
          className="w-full btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            if (!loading) {
              setStep(2);
              setValue("file", "");
            }
          }}
        >
          <ArrowLongLeftIcon className="h-4 w-4 mr-1" /> Back
        </button>
      </div>
    </>
  );
}

export default ThreeStepForm;
