import { useContext } from "react";
import { UserContext } from "../contexts/user/user-context";

interface UserContextType {
  userState: any;
  userDispatch: any;
}
export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context)
    throw new Error("useUserContext context must be use inside UserProvider");

  return context as UserContextType;
};
