import React from "react";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";

type ConfirmModalProps = {
  isOpen: boolean;
  message: {
    currency: string;
    company: string;
    network: string;
    transaction: string;
    department: string;
    walletAmount: string;
    paymentDescription: string;
    currentDate: string;
  };
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  message,
  title,
  onConfirm,
  onCancel,
}) => {
  const [colorScheme] = useThemeParams();

  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal visible opacity-100 w-screen">
      <label className="modal-overlay" />
      <div
        className={`modal-content flex flex-col gap-3 max-w-3xl w-full ${
          colorScheme === "dark" ? "bg-gray-800" : "bg-gray-100"
        }`}
      >
        <button
          className={`btn btn-xs btn-circle btn-ghost absolute right-2 top-2 text-2xl hover:bg-transparent
          ${colorScheme === "dark" ? "text-white" : "text-black"}
         `}
          onClick={onCancel}
        >
          ✕
        </button>
        <h2 className="text-xl font-semibold">{title}</h2>
        <div className="pb-4 pr-4 flex flex-col gap-4 h-80 overflow-y-auto">
          <div>
            <p className="font-bold">Company:</p>
            <p className="text-xs capitalize">{message.company}</p>
          </div>
          <div>
            <p className="font-bold">Option:</p>
            <p className="text-xs capitalize">{message.transaction}</p>
          </div>
          {message.department && (
            <div>
              <p className="font-bold">Department:</p>
              <p className="text-xs capitalize">{message.department}</p>
            </div>
          )}

          <div>
            <p className="font-bold">Currency:</p>
            <p className="text-xs">{message.currency}</p>
          </div>

          <div>
            <p className="font-bold">Network:</p>
            <p className="text-xs">{message.network}</p>
          </div>
          <div>
            <p className="font-bold">Wallet amount:</p>
            <p className="text-xs">{message.walletAmount}</p>
          </div>
          <div>
            <p className="font-bold">Payment description:</p>
            <p className="text-xs">{message.paymentDescription || "N/A"}</p>
          </div>
          <div>
            <p className="font-bold">Current date:</p>
            <p className="text-xs">{message.currentDate}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <button className="btn btn-success btn-block" onClick={onConfirm}>
            OK
          </button>

          <button className="btn btn-block" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
