import { useInitData } from "@vkruglikov/react-telegram-web-app";
import React, { ReactNode, useEffect, useReducer } from "react";
import { UserContext } from "./user-context";
import userReducer from "./user-reducer";
import axios from "axios";

interface UserProviderProps {
  children: ReactNode;
}

const initialUserState = {
  loading: true,
  error: null,
  isValid: false,
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [initDataUnsafe, initData] = useInitData();
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  useEffect(() => {
    async function fetchUser(id: number | undefined) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACK_API}/users/${id}`,
          {
            headers: {
              Authorization: initData,
            },
          },
        );

        userDispatch({ type: "INIT_DATA", payload: response.data });
      } catch (e: any) {
        if (e.response?.status === 403) {
          userDispatch({
            type: "SET_ERROR",
            payload: e?.response?.data?.message || "Something went wrong",
          });
        }
      } finally {
        userDispatch({ type: "SET_LOADING", payload: false });
      }
    }

    fetchUser(initDataUnsafe?.user?.id);
  }, [initData, initDataUnsafe?.user?.id]);

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
};
