import React, { useCallback } from "react";
import { Controller } from "react-hook-form";
import { ArrowLongRightIcon } from "@heroicons/react/16/solid";

interface OneStepFormProps {
  control: any;
  errors: any;
  trigger: any;
  watch: any;
  setStep: (step: number) => void;
  setValue: (field: any, value: any) => void;
}

function OneStepForm({
  control,
  errors,
  trigger,
  setStep,
  setValue,
  watch,
}: OneStepFormProps) {
  const handleNextStep = useCallback(
    (e: any, value: string) => {
      e.preventDefault();
      setValue("transaction", value);
    },
    [setValue],
  );

  return (
    <>
      <div>
        <p className="mb-1">Please choose company:</p>
        <div className="btn-group btn-group-scrollable w-full max-w-full">
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <>
                <input
                  type="radio"
                  data-content="Pontem"
                  className={`btn btn-primary w-full`}
                  {...field}
                  onChange={() => field.onChange("pontem")}
                  style={{
                    borderRight: "1px solid var(--tg-theme-bg-color)",
                    ...(field.value === "pontem" && {
                      backgroundColor:
                        "rgb(var(--secondary)/var(--tw-bg-opacity))",
                    }),
                  }}
                />
                <input
                  type="radio"
                  data-content="Lumio"
                  className={`btn btn-primary w-full`}
                  {...field}
                  onChange={() => field.onChange("lumio")}
                  style={{
                    ...(field.value === "lumio" && {
                      backgroundColor:
                        "rgb(var(--secondary)/var(--tw-bg-opacity))",
                    }),
                  }}
                />
              </>
            )}
          />
        </div>
      </div>

      {errors.company && (
        <p className="text-red-700 text-xs">
          {errors.company.message as string}
        </p>
      )}

      <div>
        <h1 className="mb-1">Choose your option:</h1>
        <div className="flex flex-col space-y-2">
          <div className="flex gap-2">
            <button
              className={`w-full btn ${watch("transaction") === "payment" ? "btn-secondary" : "btn-primary"}`}
              onClick={(e) => handleNextStep(e, "payment")}
            >
              Internal request
            </button>
            <button
              className={`w-full btn ${watch("transaction") === "refund" ? "btn-secondary" : "btn-primary"}`}
              onClick={(e) => handleNextStep(e, "refund")}
            >
              Refund
            </button>
          </div>

          <div className="flex gap-2">
            <button
              className={`w-full btn ${watch("transaction") === "external" ? "btn-secondary" : "btn-primary"}`}
              onClick={(e) => handleNextStep(e, "external")}
            >
              External request
            </button>
          </div>
        </div>
      </div>

      {errors.transaction && (
        <p className="text-red-700 text-xs">
          {errors.transaction.message as string}
        </p>
      )}

      <div className="flex gap-2">
        <button
          className="w-full btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            trigger(["company", "transaction"]).then(
              (r: boolean) => r && setStep(2),
            );
          }}
        >
          Next
          <ArrowLongRightIcon className="h-4 w-4 ml-1" />
        </button>
      </div>
    </>
  );
}

export default OneStepForm;
