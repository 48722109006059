import React, { useEffect, useRef, useState } from "react";
import { MainButton } from "@vkruglikov/react-telegram-web-app";

function BottomMenu({ handlePage }: any) {
  const menuRef = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {openMenu && (
        <div
          className="fixed bottom-0 left-0 right-0 flex flex-col p-4 z-50 gap-4 bg-[var(--tg-theme-bg-color)]"
          ref={menuRef}
        >
          <button
            className="btn btn-primary"
            onClick={() => {
              handlePage(2);
              setOpenMenu(false);
            }}
          >
            Start over
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handlePage(1);
              setOpenMenu(false);
            }}
          >
            Update registration
          </button>
        </div>
      )}
      <MainButton
        text="Menu"
        onClick={() => {
          setOpenMenu((prevState) => !prevState);
        }}
      />
    </>
  );
}

export default BottomMenu;
