import React, { useEffect, useState } from "react";
import OnePage from "./component/steps/one";
import TwoPage from "./component/steps/two";
import ThreePage from "./component/steps/three";
import { useUserContext } from "./hooks/use-user-context";
import BottomMenu from "./component/bottomMenu";
import ErrorComponent from "./component/error";

function App() {
  const { userState } = useUserContext();
  const [page, setPage] = useState(1);
  const [isStartOver, setIsStartOver] = useState(false);

  const handlePage = (page: number) => {
    setPage(page);
    setIsStartOver(true);
  };

  const renderPage = () => {
    switch (page) {
      case 1:
        return <OnePage handlePage={handlePage} />;
      case 2:
        return (
          <TwoPage
            handlePage={handlePage}
            isStartOver={isStartOver}
            setIsStartOver={setIsStartOver}
          />
        );
      case 3:
        return <ThreePage handlePage={handlePage} />;
      default:
        return <ErrorComponent error={userState.error} />;
    }
  };

  useEffect(() => {
    if (userState.id) {
      setPage(2);
    } else if (userState.error) {
      setPage(0);
    }
  }, [userState.error, userState.id]);

  return (
    <>
      <div className="container mx-auto p-3">
        <div className="flex justify-center">{renderPage()}</div>
      </div>
      {userState.id && <BottomMenu handlePage={handlePage} />}
    </>
  );
}

export default App;
