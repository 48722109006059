import React, { useCallback } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { useUserContext } from "../../../hooks/use-user-context";
import axios from "axios";

type PageProps = {
  handlePage: (arg0: number) => void;
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Full Name is required")
    .min(2, "Full Name must be at least 2 characters")
    .max(50, "Full Name must be at most 50 characters"),
});

function OnePage({ handlePage }: PageProps) {
  const [initDataUnsafe, initData] = useInitData();
  const { userState, userDispatch } = useUserContext();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { name: userState.name },
  });

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        let response;
        const telegramData = {
          telegram_id: Number(initDataUnsafe?.user?.id),
          first_name: initDataUnsafe?.user?.first_name,
          last_name: initDataUnsafe?.user?.last_name,
          username: initDataUnsafe?.user?.username,
          language_code: initDataUnsafe?.user?.language_code,
        };

        if (!userState.id) {
          response = await axios.post(
            `${process.env.REACT_APP_BACK_API}/users`,
            {
              name: data.name,
              ...telegramData,
            },
            {
              headers: {
                Authorization: initData,
              },
            },
          );
        } else {
          response = await axios.patch(
            `${process.env.REACT_APP_BACK_API}/users/${userState.id}`,
            {
              name: data.name,
              ...telegramData,
            },
            {
              headers: {
                Authorization: initData,
              },
            },
          );
        }
        userDispatch({ type: "UPDATE_USER", payload: response.data });
        handlePage(2);
      } catch (error: any) {
        setError("name", {
          type: "server",
          message: error?.response?.data?.message || "Something went wrong",
        });
      }
    },
    [
      initDataUnsafe?.user?.id,
      initDataUnsafe?.user?.first_name,
      initDataUnsafe?.user?.last_name,
      initDataUnsafe?.user?.username,
      initDataUnsafe?.user?.language_code,
      userState.id,
      userDispatch,
      handlePage,
      initData,
      setError,
    ],
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-3 w-full max-w-lg"
    >
      <div>
        <h1 className="mb-1">Please add you name:</h1>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <input
              className="input input-block input-primary"
              placeholder="Full Name"
              {...field}
            />
          )}
        />
      </div>

      {errors.name && (
        <p className="text-red-700 text-xs">{errors.name.message as string}</p>
      )}

      <div className="flex justify-center">
        <button
          className="btn btn-primary w-full"
          type="submit"
          disabled={userState.loading}
        >
          {userState.loading ? (
            <svg
              className="spinner-ring spinner-success spinner-sm"
              viewBox="25 25 50 50"
              strokeWidth="5"
            >
              <circle cx="50" cy="50" r="20" />
            </svg>
          ) : (
            "Next"
          )}
        </button>
      </div>
    </form>
  );
}

export default OnePage;
