import React from "react";
import { Controller } from "react-hook-form";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
} from "@heroicons/react/16/solid";

interface TwoStepFormProps {
  clearErrors: () => void;
  setStep: (step: number) => void;
  control: any;
  errors: any;
  watch: any;
  trigger: any;
}

function TwoStepForm({
  setStep,
  control,
  errors,
  watch,
  trigger,
}: TwoStepFormProps) {
  return (
    <>
      <div>
        <p className="mb-1">Please add you name:</p>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <input
              className="input input-block input-primary"
              placeholder="Full Name"
              {...field}
            />
          )}
        />
      </div>

      {errors.name && (
        <p className="text-red-700 text-xs">{errors.name.message}</p>
      )}

      <div>
        <p className="mb-1">Please add description:</p>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <input
              className="input input-block input-primary"
              placeholder="Payment description"
              {...field}
            />
          )}
        />
      </div>

      {errors.description && (
        <p className="text-red-700 text-xs">{errors.description.message}</p>
      )}

      {watch("transaction") === "payment" && (
        <div>
          <p className="mb-1">Choose your department:</p>

          <div className="btn-group btn-group-vertical w-full max-w-full">
            <Controller
              name="department"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    type="radio"
                    data-content="Development"
                    className={`btn btn-primary w-full`}
                    {...field}
                    onChange={() => field.onChange("development")}
                    style={{
                      ...(field.value === "development" && {
                        backgroundColor:
                          "rgb(var(--secondary)/var(--tw-bg-opacity))",
                      }),
                    }}
                  />
                  <input
                    type="radio"
                    data-content="Marketing"
                    className={`btn btn-primary w-full`}
                    {...field}
                    onChange={() => field.onChange("marketing")}
                    style={{
                      borderTop: "1px solid var(--tg-theme-bg-color)",
                      borderBottom: "1px solid var(--tg-theme-bg-color)",
                      ...(field.value === "marketing" && {
                        backgroundColor:
                          "rgb(var(--secondary)/var(--tw-bg-opacity))",
                      }),
                    }}
                  />
                  <input
                    type="radio"
                    data-content="Management"
                    className={`btn btn-primary w-full`}
                    {...field}
                    onChange={() => field.onChange("management")}
                    style={{
                      ...(field.value === "management" && {
                        backgroundColor:
                          "rgb(var(--secondary)/var(--tw-bg-opacity))",
                      }),
                    }}
                  />
                  <input
                    type="radio"
                    data-content="Сounterparty"
                    className={`btn btn-primary w-full`}
                    {...field}
                    onChange={() => field.onChange("counterparty")}
                    style={{
                      borderTop: "1px solid var(--tg-theme-bg-color)",
                      borderBottom: "1px solid var(--tg-theme-bg-color)",
                      ...(field.value === "counterparty" && {
                        backgroundColor:
                          "rgb(var(--secondary)/var(--tw-bg-opacity))",
                      }),
                    }}
                  />
                </>
              )}
            />
          </div>
          {errors.department && (
            <p className="text-red-700 text-xs">
              {errors.department.message as string}
            </p>
          )}
        </div>
      )}

      <div className="flex gap-2">
        <button className="w-full btn btn-primary" onClick={() => setStep(1)}>
          <ArrowLongLeftIcon className="h-4 w-4 mr-1" />
          Back
        </button>
        <button
          className="w-full btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            trigger(["name", "description", "department"]).then(
              (r: boolean) => r && setStep(3),
            );
          }}
        >
          Next <ArrowLongRightIcon className="h-4 w-4 ml-1" />
        </button>
      </div>
    </>
  );
}

export default TwoStepForm;
