import React from "react";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

type PageProps = {
  handlePage: (arg0: number) => void;
};

function ThreePage({ handlePage }: PageProps) {
  return (
    <>
      <BackButton onClick={() => handlePage(2)} />

      <h1 className="text-4xl font-semibold">Thank you!</h1>
    </>
  );
}

export default ThreePage;
