import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
import "./index.scss";
import { UserProvider } from "./contexts/user/user-provider";
import { GameConsumer } from "./contexts/user/user-consumer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <WebAppProvider
      options={{
        smoothButtonsTransition: true,
      }}
    >
      <UserProvider>
        <GameConsumer>
          <App />
        </GameConsumer>
      </UserProvider>
    </WebAppProvider>
  </React.StrictMode>,
);
